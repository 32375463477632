import React from 'react'
import { Button, TextField } from '@mui/material'
import { Col, Row } from 'reactstrap'
import { defaultFieldVariant, __ } from '../../app'
import MuiPhoneNumber from 'material-ui-phone-number'
import AddressFields from '../address-fields/address-fields'
import { useSelector } from 'react-redux'



export default function VendorEditor( { vendorModel, isVendorModelLoading, onVendorModelChanged, ActionsComponent = false } ) {
    const role = useSelector(state => state.auth.user?.profile?.role)
    const isEditsDisabled = role != 'AdminUser';
    
    function onNameChanged( event ) {
        onVendorModelChanged?.( {
            ...vendorModel,
            name: event.target.value
        } )
    }

    function onEmailChanged( event ) {
        onVendorModelChanged?.( {
            ...vendorModel,
            email: event.target.value
        } )
    }

    function onContactNameChanged( event ) {
        onVendorModelChanged?.( {
            ...vendorModel,
            contactName: event.target.value
        } )
    }

    function onCompanyNameChanged( event ) {
        onVendorModelChanged?.( {
            ...vendorModel,
            companyName: event.target.value
        } )
    }

    function onPhoneChanged( value ) {
        onVendorModelChanged?.( {
            ...vendorModel,
            phone: value
        } )
    }

    function onAddressChanged( address ) {
        onVendorModelChanged?.( {
            ...vendorModel,
            address: {
                ... address
            }
        } )
    }

    return (
        <div className="components__vendor-editor">
            <Row>
                <Col md="7" className='mb-5'>
                    <h4 className='mb-3'>{__( "Vendor Details" )}</h4>

                    { vendorModel?.id > 0 &&
                        <TextField
                            className="w-100 mb-3" 
                            label={ __( "Vendor Id" ) }
                            disabled={ true || isEditsDisabled }
                            variant={ defaultFieldVariant } 
                            value={ isVendorModelLoading ? __( 'Loading...' ) : vendorModel.id }
                            onChange={ e => false }
                        />
                    }    

                    { vendorModel?.accountId > 0 &&
                        <TextField
                            className="w-100 mb-3" 
                            label={ __( "Account Id" ) }
                            disabled={ true || isEditsDisabled }
                            variant={ defaultFieldVariant } 
                            value={ isVendorModelLoading ? __( 'Loading...' ) : vendorModel.accountid }
                            onChange={ e => false }
                        />
                    }    

                    <TextField
                        className="w-100 mb-3" 
                        label={ __( "Name" ) }
                        variant={ defaultFieldVariant } 
                        disabled={ isVendorModelLoading || isEditsDisabled }
                        value={ isVendorModelLoading ? __( 'Loading...' ) : ( vendorModel?.name || '' ) }
                        onChange={ e => onNameChanged( e ) }
                    />

                    <TextField
                        className="w-100 mb-3" 
                        label={ __( "Email" ) }
                        variant={ defaultFieldVariant } 
                        disabled={ isVendorModelLoading || isEditsDisabled}
                        value={ isVendorModelLoading ? __( 'Loading...' ) : ( vendorModel?.email || '' ) }
                        onChange={ e => onEmailChanged( e ) }
                    />

                    <TextField
                        className="w-100 mb-3" 
                        label={ __( "Contact Name" ) }
                        variant={ defaultFieldVariant } 
                        disabled={ isVendorModelLoading || isEditsDisabled }
                        value={ isVendorModelLoading ? __( 'Loading...' ) : ( vendorModel?.contactName || '' ) }
                        onChange={ e => onContactNameChanged( e ) }
                    />

                    <TextField
                        className="w-100 mb-3" 
                        label={ __( "Company Name" ) }
                        variant={ defaultFieldVariant } 
                        disabled={ isVendorModelLoading || isEditsDisabled }
                        value={ isVendorModelLoading ? __( 'Loading...' ) : ( vendorModel?.companyName || '' ) }
                        onChange={ e => onCompanyNameChanged( e ) }
                    />

                    <MuiPhoneNumber
                        defaultCountry='au'
                        label={ __( "Phone" ) }
                        variant={ defaultFieldVariant } 
                        className="w-100 mb-3" 
                        disabled={ isVendorModelLoading || isEditsDisabled }
                        value={ vendorModel?.phone }
                        onChange={ value => onPhoneChanged( value ) }
                    />

                    <AddressFields
                        address={ vendorModel?.address || {} }
                        onChange={ address => onAddressChanged( address ) }
                        isAddressLoading={ isVendorModelLoading }
                        disabled={ isVendorModelLoading || isEditsDisabled  }
                    />

                    { !isEditsDisabled && ActionsComponent && <ActionsComponent /> }
                </Col>
                <Col md="4" className='mb-5'>
                    {/* <h4 className='mb-3'>{__( "API Keys" )}</h4>

                    <TextField
                        className="w-100 mb-3" 
                        label={ __( "Client Id" ) }
                        disabled={ true }
                        variant={ defaultFieldVariant } 
                        value={ isVendorModelLoading ? __( 'Loading...' ) : ( vendorModel?.clientId || '0ece8833-7779-41a1-ac45-d1c113570628' )}
                        onChange={ e => false }
                    />

                    <TextField
                        className="w-100 mb-3" 
                        label={ __( "Client Secret" ) }
                        disabled={ true }
                        variant={ defaultFieldVariant } 
                        value={ isVendorModelLoading ? __( 'Loading...' ) : "******************" }
                        onChange={ e => false }
                    />
                    
                    <Button variant="contained">{ __( 'Regenerate Secret' ) }</Button> */}
                </Col>
            </Row>
        </div>
    );
}


