import React, { Component, useState } from 'react'
import * as Icon from 'react-feather';
import { Card, Row, Col, Container, CardBody } from 'reactstrap'
import { Radar } from 'react-chartjs-2';

// import { signoutRedirect } from '../services/userService'
// import { useSelector } from 'react-redux'
// import * as apiService from '../services/apiService'
// import { prettifyJson } from '../utils/jsonUtils'


function AnaylitcsCard({ title = "", icon = false, value = "", sinceLastWeek = 12.5 }) {
    var Icon = icon;
    return (
        <Card>
            <CardBody>
                <Row>
                    <Col className="col mt-0">
                        <h5 className="card-title">{title}</h5>
                    </Col>
                    <Col className="col-auto">
                        <div className="stat text-primary">
                            <Icon />
                        </div>
                    </Col>
                </Row>
                <h1 className="mt-1 mb-3">{value}</h1>
                <div className="mb-0">
                    <span className={sinceLastWeek < 0 ? 'text-danger' : 'text-success'}> <i className="mdi mdi-arrow-bottom-right"></i> {sinceLastWeek.toFixed(2) + "%"} </span>
                    <span className="text-muted">Since last week</span>
                </div>
            </CardBody>
        </Card>
    );


}


export default class Dashboard extends Component {

    constructor() {
        super();
    }


    render() {

        const pieData = {
            labels: [ 'VegetationLink','iConservate', 'Protech-ed' ],
            datasets: [
                {
                    label: 'Sales by Vendor',
                    data: [522, 192, 331],
                    backgroundColor: [
                        'rgba(255, 99, 132, 0.2)',
                        'rgba(54, 162, 235, 0.2)',
                        'rgba(255, 206, 86, 0.2)',
                    ],
                    borderColor: [
                        'rgba(255, 99, 132, 1)',
                        'rgba(54, 162, 235, 1)',
                        'rgba(255, 206, 86, 1)',
                    ],
                    borderWidth: 1,
                },
            ],
        };

        
        const radarData = {
            labels: ['Biome 1', 'Biome 2', 'Desert', 'Grasslands', 'Wetlands', 'Swamplands'],
            datasets: [
            {
                label: 'Average customer distribution',
                data: [221, 193, 283, 253, 222, 231],
                backgroundColor: 'rgba(255, 99, 132, 0.2)',
                borderColor: 'rgba(255, 99, 132, 1)',
                borderWidth: 1,
            },
            ],
        };

        
        const radarOptions = {
            scale: {
            ticks: { beginAtZero: true },
            },
        };
  


        return (
            <div className="view__dashboard">
                <Container fluid>
                    <h1 className="h3 mb-3">
                        Welcome back, <strong>USERNAME</strong>!
                    </h1>

                    <div className="w-100">
                        <Row>
                            <Col sm="6" lg="4">
                                <AnaylitcsCard
                                    title="Orders"
                                    icon={ Icon.Smile }
                                    value="1,242"
                                    sinceLastWeek={35.65}
                                />
                                <AnaylitcsCard
                                    title="Units Sold"
                                    icon={ Icon.MapPin }
                                    value="564,322"
                                    sinceLastWeek={18.22}
                                />
                            </Col>
                            <Col sm="6" lg="4">
                                <AnaylitcsCard
                                    title="Units Remaining"
                                    icon={ Icon.MapPin }
                                    value="32,424,112"
                                    sinceLastWeek={-4.22}
                                />
                                <AnaylitcsCard
                                    title="Unresolved vendor tickets"
                                    icon={ Icon.Frown }
                                    value="12"
                                    sinceLastWeek={-25.50}
                                />
                            </Col>
                            <Col sm="4">      
                                <Card>
                                    <CardBody>                     
                                        <Radar data={radarData} options={radarOptions} />
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </div>

                </Container>
            </div>
        );
    }

}

// function Dashboard() {
//     const user = useSelector(state => state.auth.user)
//     const [doughnutData, setDoughnutData] = useState(null)
//     function signOut() {
//         signoutRedirect()
//     }

//     async function getDoughnuts() {
//         const doughnuts = await apiService.getDoughnutsFromApi()
//         setDoughnutData(doughnuts)
//     }


//     return (
//         <div>
//             <h1>Home</h1>
//             <p>Hello, {user.profile.given_name}.</p>
//             <p>I have given you a token to call your favourite doughnut based API 🍩</p>

//             <p>💡 <strong>Tip: </strong><em>Use the Redux dev tools and network tab to inspect what user data was returned from identity and stored in the client.</em></p>

//             <button className="button button-outline" onClick={() => getDoughnuts()}>Get Doughnuts</button>
//             <button className="button button-clear" onClick={() => signOut()}>Sign Out</button>

//             <pre>
//                 <code>
//                     {prettifyJson(doughnutData ? doughnutData : 'No doughnuts yet :(')}
//                 </code>
//             </pre>
//             <p><a target='_blank' rel='noopener noreferrer' href='https://github.com/tappyy/react-IS4-auth-demo'>Github Repo</a></p>

//         </div>
//     )
// }

// export default Dashboard
