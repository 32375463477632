export const enumOrderStatus = [
    'COMPLETED',
    'PENDING',
    'CANCELLED',
    'REFUNDED'
];

export const enumVegetationTypes = [
    'Desert',
    'Woodlands',
    'Coastlands',
    'Rainforests',
    'Wetlands',
    'Grasslands',
    'Dry Forest'
]

export const enumCreditNames = [
    'VBU'
];

export const enumZoneStatus = [
    'ACTIVE',
    'PENDING',
    'RETIRED'
]